<script setup lang="ts">
/**
 * @file Provides button functionality, but not styling.
 */
import type { LongPressEvent } from '@@/vuedirectives/emit_long_press'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * Automatically uses an `<a>` tag if href is present, `<button>` otherwise.
     */
    href?: string
    /**
     * Whether the button is disabled.
     */
    disabled?: boolean
    /**
     * Defines the button functionality.
     */
    hrefMode?: OzBaseButtonHrefMode
  }>(),
  {
    href: undefined,
    disabled: false,
    hrefMode: OzBaseButtonHrefMode.Default,
  },
)

const root = ref<HTMLElement>()

const emit = defineEmits<{
  (name: 'click', event: MouseEvent): void
  (name: 'mouseenter', event: MouseEvent): void
  (name: 'mouseleave', event: MouseEvent): void
  (name: 'mousedown', event: MouseEvent): void
  (name: 'keydown', event: KeyboardEvent): void
  (name: 'keyup', event: KeyboardEvent): void
  (name: 'focus', event: FocusEvent): void
  (name: 'blur', event: FocusEvent): void
  (name: 'contextmenu', event: MouseEvent): void
  (name: 'long-press', event: LongPressEvent): void
  (name: 'touchstart', event: TouchEvent): void
  (name: 'touchend', event: TouchEvent): void
  (name: 'pointerup', event: PointerEvent): void
  (name: 'pointerenter', event: PointerEvent): void
  (name: 'pointerleave', event: PointerEvent): void
  (name: 'pointerdown', event: PointerEvent): void
  (name: 'dblclick', event: MouseEvent): void
  (name: 'auxclick', event: PointerEvent): void
}>()

const click = (event: MouseEvent): void => {
  if (props.hrefMode !== OzBaseButtonHrefMode.Default) event.preventDefault()
  if (props.disabled) {
    event.preventDefault()
    return
  }
  if (props.hrefMode === OzBaseButtonHrefMode.HistoryPushState) {
    // Store the url in the history state so you can read it later
    window?.history?.pushState({ url: props.href }, '', props.href)
  }
  if (props.hrefMode === OzBaseButtonHrefMode.NewTab && props.href) {
    window.open(props.href, '_blank')
  }

  emit('click', event)
}

const handleSpaceKey = (event: KeyboardEvent): void => {
  // Anchor elements with roles set to ‘button’ can only be activated with the ‘enter’ key,
  // whereas a button element would be triggered by both ‘space’ and ‘enter’ keys.
  // Adding a space keypress event listener and calling this method should ensure
  // that the element triggers the same event that a click, or pressing the ‘enter’ key
  // would (mouse click event) and execute the default behavior.
  const SPACE_KEYCODE = event.code === 'Space'
  if (props.href && SPACE_KEYCODE) {
    event.preventDefault()
    // Prevent default scrolling behaviour on anchor tags
    const linkButton = root.value as HTMLAnchorElement
    linkButton?.click()
  }
}
</script>

<script lang="ts">
export enum OzBaseButtonHrefMode {
  /** Triggers a page reload */
  Default = 'Default',
  /** `href` is for show only. Handle it using `@click` */
  DisplayOnly = 'DisplayOnly',
  /** Changes current URL. Handle it using `@click` */
  HistoryPushState = 'HistoryPushState',
  /** Opens page in new tab */
  NewTab = 'NewTab',
}

export default {}
</script>

<template>
  <component
    :is="href ? 'a' : 'button'"
    ref="root"
    :aria-disabled="disabled"
    :href="href"
    :rel="href && 'noopener nofollow'"
    :class="[
      href
        ? [
            // Reset <a>
            'no-underline',
          ]
        : [
            // Reset <button>
            'select-none',
            'border-none',
            // Let the parent component reset/override the default user agent padding and background.
            // We will run into specifity issues if we reset them here.
          ],
      // Remove the styles that appear when tapping on button/links on mobile devices.
      'tap-highlight-transparent',
      // Cursor
      !disabled && 'cursor-pointer',
      disabled && 'cursor-not-allowed',
      // Override kit.scss styles
      'font-sans',
    ]"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @mousedown="!disabled && $emit('mousedown', $event)"
    @keydown="!disabled && $emit('keydown', $event)"
    @keyup="!disabled && $emit('keyup', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @contextmenu="!disabled && $emit('contextmenu', $event)"
    @click="click"
    @keypress.space="handleSpaceKey"
    @long-press="!disabled && $emit('long-press', $event)"
    @touchstart="!disabled && $emit('touchstart', $event)"
    @touchend="!disabled && $emit('touchend', $event)"
    @pointerup="!disabled && $emit('pointerup', $event)"
    @pointerenter="$emit('pointerenter', $event)"
    @pointerleave="$emit('pointerleave', $event)"
    @pointerdown="!disabled && $emit('pointerdown', $event)"
    @dblclick="!disabled && $emit('dblclick', $event)"
    @auxclick="!disabled && $emit('auxclick', $event)"
  >
    <slot></slot>
  </component>
</template>
